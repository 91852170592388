<template>
  <section class="terms-of-service">
    <h1 class="title bigger">Please make sure to read the below</h1>
<!--    <p class="md-helper-text paragraph">-->
<!--      To use Fac3d, you need to follow 3 simple steps:-->
<!--      <span>Upload an image with a face in it.</span><br/>-->
<!--      Support us with a small monthly fee, or watch an ad<br/>-->
<!--      <span>Transform your image in 3D!</span>-->
<!--    </p>-->
    <md-card class="md-layout-item md-size-50 md-small-size-100">
      <md-card-content>
        <AboutComponent></AboutComponent>
      </md-card-content>
    </md-card>
  </section>
</template>
<script type="text/babel">
export default {
  name: 'Fac3dTermsOfService',
  components: {
    AboutComponent: () => import('@/components/AboutComponent'),
  },
};
</script>
<style scoped lang="scss">
.terms-of-service {
  padding: 0 12px;
  .title.bigger {
    font-size: 22px;
    color: white;
  }
  .md-card.md-theme-default {
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(80px);
    filter: drop-shadow(6px 4px 4px rgba(57, 40, 166, 0.3));
    * {
      color: #000001 !important;
      -webkit-text-fill-color: #333;
    }
  }
  .md-helper-text {
    color: white;
  }
}
</style>
